<template>
  <div class="login-register p-0">
    <b-card class="login-box mb-0">
      <figure class="text-center">
        <b-img src="/assets/images/logo.png" />
      </figure>
      <figure class="text-center">
        <h3>Log In</h3>
        <p>Please sign-in to your account to continue</p>
      </figure>
      <b-overlay
        :show="loadingOverlay"
        rounded="sm"
      >
        <validation-observer
          v-if="type=='login'"
          v-slot="{ handleSubmit }" ref="VForm"
        >
          <b-form 
            @submit.prevent="handleSubmit(doLogin('/do/login'))"
            class="form-horizontal form-material"
          >
            <b-form-group>
              <b-form-input v-model="input.username" placeholder="Username" />
              <VValidate 
                name="Username" 
                v-model="input.username" 
                rules="required"
              />
            </b-form-group>
            <b-form-group>
              <b-input-group>
                <b-form-input :type="(pwdOpen?'text':'password')" v-model="input.password" placeholder="Password" />
                <b-input-group-append>
                  <b-button variant="outline" @click="pwdOpen=!pwdOpen"><i :class="{'fas fa-eye':!pwdOpen, 'fas fa-eye-slash': pwdOpen}"></i></b-button>
                </b-input-group-append>
              </b-input-group>
              <VValidate 
                name="Password" 
                v-model="input.password" 
                rules="required"
              />
            </b-form-group>
            <div class="infoHTML" id="info-login"></div>
            <b-form-group>
              <b-form-checkbox v-model="input.rememberMe">Remember Me</b-form-checkbox>
            </b-form-group>
            <b-form-group>
              <b-button
               variant="primary" :disabled="loading ? true : false" class="btn-rounded btn-block" size="lg" type="submit"
              >
                <span class="mr-2">Log In</span>
                <b-spinner v-if="loading" small />
              </b-button>
            </b-form-group>
          </b-form>
        </validation-observer>
      </b-overlay>
      <p class="text-center">Copyright {{Config.app.copyright()}} &copy; {{Config.app.company}}. All Rights Reserved.</p>
    </b-card>
  </div>
</template>

<script>
import GlobalVue from '@/libs/Global'
import $ from 'jquery'

export default {
  extends:GlobalVue,
  data() {
    return {
      type:'login',
      pwdOpen: false,
      loading: false
    }
  },
  methods: {
    doLogin(action){
      this.$refs['VForm'].validate().then(success => {
        if (!success) return
        var infoLogin = $("#info-login")
        infoLogin.attr("class","infoHTML alert alert-info")
        $("#info-login").html("Authenticating and authorizing...")
        this.loading = true
        setTimeout(()=>{
          this.doSubmit(action, this.input, (_, resp)=>{
            this.loading = false
            if (resp.status != 'success') return false

            if (!resp.data.token) return this.type = "2fa"
      
            this.$store.commit('updateUserToken', { token: resp.data.token })
            this.$store.commit('updateUser', { user: resp.data.user })


            if(this.$route.query.continue) {
              return window.location=this.baseUrl+''+this.$route.query.continue
            }
            window.location=this.baseUrl+'/dashboard'
          })
        },2000)
      })
    }
  },
}
</script>

<style lang="scss" scoped>
  .login-register {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 100%;
    width: 100%;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("/assets/images/login-bg.jpg");
  }
  .login-box {
    width: 400px;
    margin: 0 auto;
    img{ width: 180px; }
  }
</style>